export const strapiGetI18nSlug = (locale: string) => {
  switch (locale) {
    case 'en-US':
      return 'i18n_slug_en'
    case 'es-ES':
      return 'i18n_slug_es'
    case 'ca-ES':
      return 'i18n_slug_ca'
    default:
      return 'i18n_slug_en'
  }
}
